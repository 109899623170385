var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col md:flex-row lg:flex-col md:justify-between md:space-x-2 border rounded bg-white px-4 py-4 lg:py-8"},[_c('div',{staticClass:"h-28 w-full"},[_c('h2',{staticClass:"text-sm font-semibold border-b"},[_vm._v("Navigation")]),_c('ul',{staticClass:"list-none mt-2 space-y-1"},[(
        _vm.can('Personal', 'Mitarbeitergespräche', 'Mitarbeitergespräche', 'view', ['All', 'Only own unit data', 'Only own personal data'])
      )?_c('li',[_c('router-link',{staticClass:"pl-2 border-l",class:_vm.activeLink('employee-performance-review'),attrs:{"to":"/personal/my-performance-evaluation"}},[_vm._v("Meine Mitarbeitergespräche")])],1):_vm._e(),(
        _vm.can('Personal', 'Mitarbeitergespräche', 'Mitarbeitergespräche', 'view', ['Only own unit data'])
      )?_c('li',[_c('router-link',{staticClass:"pl-2 border-l",class:_vm.activeLink('team-performance-review'),attrs:{"to":"/personal/my-team-performance-evaluation"}},[_vm._v("Team Mitarbeitergespräche")])],1):_vm._e(),(
        _vm.can('Personal', 'Mitarbeitergespräche', 'Mitarbeitergespräche', 'view', ['All'])
      )?_c('li',[_c('router-link',{staticClass:"pl-2 border-l",class:_vm.activeLink('employee-performance-review'),attrs:{"to":"/personal/performance-evaluation"}},[_vm._v("Alle Mitarbeitergespräche")])],1):_vm._e()])]),(
    _vm.can('Personal', 'Mitarbeitergespräche', 'Mitarbeitergespräche Template', 'edit', ['All'])
  )?_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"text-sm font-semibold border-b"},[_vm._v("Einstellungen")]),_c('ul',{staticClass:"list-none mt-2 space-y-1"},[_c('li',[_c('div',{staticClass:"flex space-x-2"},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"}})]),_c('router-link',{class:_vm.activeLink('employee-performance-evaluation-templates'),attrs:{"to":"/personal/performance-evaluation/templates"}},[_vm._v("Vorlagen verwalten")])],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }