<template>
  <div
    class="flex flex-col md:flex-row lg:flex-col md:justify-between md:space-x-2 border rounded bg-white px-4 py-4 lg:py-8">
    <div class="h-28 w-full">
      <h2 class="text-sm font-semibold border-b">Navigation</h2>
      <ul class="list-none mt-2 space-y-1">
        <li v-if="
          can('Personal', 'Mitarbeitergespräche', 'Mitarbeitergespräche', 'view', ['All', 'Only own unit data', 'Only own personal data'])
        ">
          <router-link to="/personal/my-performance-evaluation" class="pl-2 border-l"
            :class="activeLink('employee-performance-review')">Meine Mitarbeitergespräche</router-link>
        </li>

        <li v-if="
          can('Personal', 'Mitarbeitergespräche', 'Mitarbeitergespräche', 'view', ['Only own unit data'])
        ">

          <router-link to="/personal/my-team-performance-evaluation" class="pl-2 border-l"
            :class="activeLink('team-performance-review')">Team Mitarbeitergespräche</router-link>
        </li>

        <li v-if="
          can('Personal', 'Mitarbeitergespräche', 'Mitarbeitergespräche', 'view', ['All'])
        ">
          <router-link to="/personal/performance-evaluation" class="pl-2 border-l"
            :class="activeLink('employee-performance-review')">Alle Mitarbeitergespräche</router-link>
        </li>

      </ul>
    </div>

    <div v-if="
      can('Personal', 'Mitarbeitergespräche', 'Mitarbeitergespräche Template', 'edit', ['All'])
    " class="w-full">
      <h2 class="text-sm font-semibold border-b">Einstellungen</h2>
      <ul class="list-none mt-2 space-y-1">
        <li>
          <div class="flex space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
            </svg>
            <router-link :class="activeLink('employee-performance-evaluation-templates')"
              to="/personal/performance-evaluation/templates">Vorlagen verwalten</router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'right-sidebar',
}
</script>

<style>
.router-link-exact-active {
  color: #33339f;
}
</style>
